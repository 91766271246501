import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import zxcvbn from "zxcvbn";
import { PasswordStrengthMeter } from ".";

function ChangePasswordForm({ visible, onUpdate, onCancel }) {
	const [form] = Form.useForm();
	const [passwordScore, setPasswordScore] = useState(0);

	const onOk = () => {
		form.validateFields()
			.then(values => {
				form.resetFields();
				onUpdate(values);
			})
			.catch(info => {
				console.log("Validate fields:", info);
			});
	};

	const onNewPasswordChange = e => {
		setPasswordScore(zxcvbn(e.target.value).score);
	};

	const validatePasswordStrength = (rule, value) => {
		if (value && zxcvbn(value).score < 3) {
			return Promise.reject("Password strength must be at least 'good'");
		} else {
			return Promise.resolve();
		}
	};

	const comparePasswords = (rule, value) => {
		if (value !== form.getFieldValue("newPassword")) {
			return Promise.reject("Passwords don't match");
		} else {
			return Promise.resolve();
		}
	};

	return (
		<Modal
			visible={visible}
			title="Change your password"
			okText="Change"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={onOk}
		>
			<Form form={form} layout="vertical" name="change_password_modal">
				<Form.Item
					label="Current password"
					name="currentPassword"
					rules={[
						{
							required: true,
							message: "Please enter your current password",
						},
					]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item label="New password">
					<Form.Item
						name="newPassword"
						rules={[
							{
								required: true,
								message: "Please enter your new password",
							},
							{
								validator: validatePasswordStrength,
							},
						]}
					>
						<Input.Password onChange={onNewPasswordChange} />
					</Form.Item>
					<PasswordStrengthMeter score={passwordScore} />
				</Form.Item>
				<Form.Item
					label="Confirm new password"
					name="confirmNewPassword"
					rules={[
						{
							required: true,
							message: "Please confirm your new password",
						},
						{
							validator: comparePasswords,
						},
					]}
				>
					<Input.Password />
				</Form.Item>
			</Form>
		</Modal>
	);
}

export { ChangePasswordForm };
