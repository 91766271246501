import React from "react";
import { Row, Col, Typography } from "antd";

const { Title, Text } = Typography;

function HomePage(props) {
	return (
		<div>
			<Row>
				<Col span={24}>
					<Title>
						<Text type="secondary">
							Welcome {props.user.firstname} {props.user.lastname}
						</Text>
					</Title>
				</Col>
			</Row>
		</div>
	);
}

export { HomePage };
