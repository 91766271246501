import React, { useEffect, useState } from "react";
import {
    Typography,
    Form,
    Input,
    Button,
    Spin,
    Space,
    message,
    Upload,
    Popconfirm,
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    InboxOutlined,
} from "@ant-design/icons";
import axios from "axios";
import sha256 from "crypto-js/sha256";

const { Title } = Typography;
const { Dragger } = Upload;

function AgWhitelist(props) {
    const [whitelist, setWhitelist] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get(
                "/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fwhitelist.json"
            )
            .then(response => {
                setWhitelist(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                message.error("Error while loading whitelist");
                setIsLoading(false);
            });
    }, []);

    const onFinish = values => {
        setIsLoading(true);
        setWhitelist(values);
        var salt = "!Yun3x1sB3st!";
        axios
            .post(
                `/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fwhitelist.json`,
                values
            )
            .then(response =>
                values.whitelist.map(item => sha256(salt + item).toString())
            )
            .then(hashedList => ({ whitelist: hashedList }))
            .then(newObject =>
                axios.post(
                    `/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fhashlist.json`,
                    newObject
                )
            )
            .then(() => {
                message.success("Whitelist has been updated");
                setIsLoading(false);
            })
            .catch(error => {
                message.error("Error while saving the whitelist");
                setIsLoading(false);
            });
    };

    const clearButton = () => {
        setIsLoading(true);
        var empty = { whitelist: [] };
        setWhitelist(empty);
        axios
            .post(
                `/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fwhitelist.json`,
                empty
            )
            .then(response =>
                empty.whitelist.map(item => sha256(item).toString())
            )
            .then(hashedList => ({ whitelist: hashedList }))
            .then(newObject =>
                axios.post(
                    `/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fhashlist.json`,
                    newObject
                )
            )
            .then(() => {
                message.success("Whitelist has been updated");
                setIsLoading(false);
            })
            .catch(error => {
                message.error("Error while saving the whitelist");
                setIsLoading(false);
            });
    };

    const useFileProcessing = ({ file, onSuccess }) => {
        if (file.name.split(".").pop() === "csv") {
            //setIsLoading(true);
            var reader = new FileReader();
            var string = "";
            reader.onload = function(e) {
                // Use reader.result
                string = reader.result;
                var list_vrm = string.split("\r\n");
                list_vrm.pop();
                var diction = { whitelist: list_vrm };
                setIsLoading(true);
                setWhitelist(diction);
                setIsLoading(false);
            };
            reader.readAsText(file);
        }
    };

    if (isLoading) return <Spin tip="Loading ..." />;
    return (
        <div>
            <Title level={2}>Whitelist</Title>

            <Form name="upload_file_form" autoComplete="off">
                <Form.Item>
                    <Dragger
                        customRequest={useFileProcessing}
                        showUploadList={false}
                        type="dashed"
                        accept=".csv"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Klik of sleep een CSV file hier om de whitelist op
                            te laden
                        </p>
                        <p className="ant-upload-text">
                            <b>Pas op!</b> Dit overschrijft de <u>volledige</u>{" "}
                            lijst.
                        </p>
                        <p className="ant-upload-hint">
                            <b>Vergeet niet op 'Indienen' te duwen achteraf!</b>
                        </p>
                    </Dragger>
                </Form.Item>
            </Form>
            <Form
                name="whitelist_form"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={whitelist}
            >
                <Form.List name="whitelist">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(
                                ({ key, name, fieldKey, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: "flex",
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name]}
                                            fieldKey={[fieldKey, "first"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "VRM missing",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Nummerplaat" />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    </Space>
                                )
                            )}
                            <Form.Item>
                                <Button
                                    name="add_license_plate"
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Voeg nummerplaat toe
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Indienen
                    </Button>
                </Form.Item>
            </Form>
            <Popconfirm
                title="Bent u zeker dat u de volledige whitelist wilt verwijderen?"
                cancelText="Cancel"
                onConfirm={e => clearButton()}
            >
                <Button>Verwijder whitelist</Button>
            </Popconfirm>
        </div>
    );
}

export { AgWhitelist };
