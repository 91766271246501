import React from "react";

function EmbeddedKibana({ url }) {
	return (
		<iframe
			title="dashboard"
			src={url}
			style={{ width: "100%", height: "80vh" }}
		/>
	);
}

export { EmbeddedKibana };
