import React from 'react';
import { useMutation } from 'react-query';
import { Form, Input, Button, Spin, Alert, Select } from 'antd';
import axios from 'axios';

const { Option } = Select;

function NewDashboardForm({ modules }) {
	const [ form ] = Form.useForm();

	const [ createDashboard, { isLoading, isError, isSuccess, error } ] = useMutation(
		({ dashboard }) => axios.post('/api/dashboards', dashboard),
		{
			onSuccess: () => window.location.reload(true)
		}
	);

	const onFinish = (values) => {
		if (!values.url) values.url = "";
		createDashboard({ dashboard: values });
	};

	if (isLoading) return <Spin tip="Loading..." />;
	if (isError) return <Alert message="Error" description={error.response.data.message} type="error" />;
	return (
		<div>
			{isSuccess && <Alert message="Dashboard successfully created !" type="success" showIcon />}
			<Form form={form} layout="inline" onFinish={onFinish}>
				<Form.Item
					label="Module"
					name="module"
					rules={[
						{
							required: true,
							message: 'Please select a module'
						}
					]}
				>
					<Select style={{ width: '300px' }}>
						{modules.map((module) => (
							<Option value={module.id} key={module.id}>
								{module.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label="Name"
					name="name"
					rules={[
						{
							required: true,
							message: 'Please input a name'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label="URL" name="url">
					<Input />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" className="login-form-button">
						Add new dashboard
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
export { NewDashboardForm };
