import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { RouteWrapper, DataCollectorRouteWrapper } from '.';
import { HomePage } from './../Home';
import { UserManagementPage } from './../UserManagement';
import { ConfigurationPage } from './../Configuration';
import { DashboardPage } from './../Dashboard';
import { LoginPage } from './../Login';
import { ProfilePage } from './../Profile';
import { DataCollectorLogin } from './../Login';

function Routes() {
	return (
		<BrowserRouter>
			<Switch>
				<DataCollectorRouteWrapper exact path="/dashboard/DataCollector" component={DashboardPage} />
				<RouteWrapper exact path="/" component={HomePage} />
				<RouteWrapper exact path="/user-management" component={UserManagementPage} />
				<RouteWrapper exact path="/configuration" component={ConfigurationPage} />
				<RouteWrapper exact path="/dashboard/:nameOfModule" component={DashboardPage} />
				<RouteWrapper exact path="/myprofile" component={ProfilePage} />
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/DataCollector/login" component={DataCollectorLogin} />
			</Switch>
		</BrowserRouter>
	);
}

export { Routes };
