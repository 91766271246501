import React, { useState } from "react";
import { Spin, Alert, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import axios from "axios";
import { UserModalForm } from "./UserModalForm";
import Swal from "sweetalert2";

function NewUserContainer({ onCreated }) {
	const [visibleForm, setVisibleForm] = useState(false);
	const [mutate, { isLoading, isError, error }] = useMutation(
		user => axios.post("/api/users", user),
		{
			onSuccess: data => {
				Swal.fire({
					icon: "success",
					title: "User created successfully !",
					showConfirmButton: true,
					html: `Please send him/her the following information.<br />Username: <i>${data.data.username}</i><br />Password: <i>${data.data.password}</i>`,
				});
				onCreated();
			},
		}
	);

	const showModal = () => setVisibleForm(true);
	const hideModal = () => setVisibleForm(false);

	const onCreate = async values => {
		const modules = Object.keys(values)
			.filter(property => property.startsWith("module."))
			.map(property => ({
				name: property.replace("module.", ""),
				role: values[property],
			}));
		const password = Math.random()
			.toString(36)
			.slice(-10);

		for (const key in values) {
			if (key.startsWith("module.")) delete values[key];
		}

		const user = { ...values, modules, password };

		hideModal();
		try {
			await mutate(user);
		} catch (error) {}
	};

	if (isLoading) return <Spin tip="Loading..." />;

	return (
		<div>
			<Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
				Add new user
			</Button>
			<UserModalForm
				isUpdate={false}
				visible={visibleForm}
				onCancel={hideModal}
				onComplete={onCreate}
			/>
			{isError && (
				<Alert
					message="Error"
					description={error.response.data.message}
					type="error"
				/>
			)}
		</div>
	);
}

export { NewUserContainer };
