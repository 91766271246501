import React, { useState } from "react";
import { Row, Col, Typography, Descriptions, Button, Spin, Alert } from "antd";
import { ChangePasswordForm } from ".";
import axios from "axios";
import { useMutation } from "react-query";

const { Title, Text } = Typography;

function ProfilePage({ user }) {
	const [visibleChangePassword, setVisibleChangePassword] = useState(false);

	const [
		updatePassword,
		{ isLoading, isError, isSuccess, error },
	] = useMutation(values =>
		axios.put(`/api/users/${user.username}/password`, values)
	);

	const changePassword = () => {
		setVisibleChangePassword(true);
	};

	const onCancel = () => {
		setVisibleChangePassword(false);
	};

	const onPasswordUpdate = values => {
		setVisibleChangePassword(false);
		updatePassword(values);
	};

	if (isLoading) return <Spin tip="Loading ..." />;

	return (
		<div>
			<Row>
				<Col span={24}>
					<Title>
						<Text type="secondary">
							{user.firstname} {user.lastname}
						</Text>
					</Title>
				</Col>
			</Row>
			<Row>
				<Col span={8}>
					<Title level={3}>User information</Title>
					<Descriptions
						column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
						bordered
					>
						<Descriptions.Item label="Username">
							{user.username}
						</Descriptions.Item>
						<Descriptions.Item label="First name">
							{user.firstname}
						</Descriptions.Item>
						<Descriptions.Item label="Last name">
							{user.lastname}
						</Descriptions.Item>
					</Descriptions>
				</Col>
				<Col offset={4} span={8}>
					<Title level={3}>Roles</Title>
					<Descriptions
						column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
						bordered
					>
						{user.modules.map((module, index) => (
							<Descriptions.Item key={index} label={module.name}>
								{module.role}
							</Descriptions.Item>
						))}
					</Descriptions>
				</Col>
			</Row>
			<Row>
				<Col span={8}>
					<Button type="primary" onClick={changePassword}>
						Change password
					</Button>
					<ChangePasswordForm
						visible={visibleChangePassword}
						onUpdate={onPasswordUpdate}
						onCancel={onCancel}
					/>
					{isError && (
						<Alert
							message="Error"
							description={error.response.data.message}
							type="error"
						/>
					)}
					{isSuccess && (
						<Alert
							message={`Password changed successfully !`}
							type="success"
						/>
					)}
				</Col>
			</Row>
		</div>
	);
}

export { ProfilePage };
