import React from "react";
import { Progress } from "antd";

function PasswordStrengthMeter({ score }) {
	const getPasswordLabel = result => {
		switch (result) {
			case 0:
				return { text: "Weak", color: "#F25F5C" };
			case 1:
				return { text: "Weak", color: "#F25F5C" };
			case 2:
				return { text: "Fair", color: "#FFE066" };
			case 3:
				return { text: "Good", color: "#247BA0" };
			case 4:
				return { text: "Strong", color: "#70C1B3" };
			default:
				return { text: "Fair", color: "#F25F5C" };
		}
	};

	const getProgressPercentage = result => (result / 4) * 100;

	const passwordLabel = getPasswordLabel(score);

	return (
		<div className="password-strength-meter">
			<Progress
				percent={getProgressPercentage(score)}
				size="small"
				showInfo={false}
				strokeColor={passwordLabel.color}
			/>
			<br />
			<label className="password-strength-meter-label">
				<strong>Password strength:</strong> {passwordLabel.text}
			</label>
		</div>
	);
}

export { PasswordStrengthMeter };
