import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Form, Select, Button, DatePicker, Alert } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useMutation } from 'react-query';
import { DownloadTable } from './DownloadTable';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import jszip from 'jszip';

const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function S3Dashboard(props) {
	const [ intersections, setIntersections ] = useState(null);
	const [ files, setFiles ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ form ] = Form.useForm();
	const [ tableLoading, setTableLoading ] = useState(false);
	const [ isDownLoading, setIsDownLoading ] = useState(false);
	const [ selectedIntersection, setIntersection ] = useState('');

	useEffect(() => {
		axios
			.get(`/api/buckets/dig.its.ictrafficonline.config/file/listofintersectionsonS3%2Fintersections.txt`)
			.then((response) => {
				setIntersections(
					response.data.split(';').sort((a, b) => {
						return a.localeCompare(b);
					})
				);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const onChange = async (e, value) => {
		setFiles([]);
		setTableLoading(true);
		setIntersection(value.key);
		const reqFilters = value.key + '%2F';
		const intersectionInfo = await getIntersectionInfo({ filter: reqFilters });
		getZipfiles({
			intersectionType: intersectionInfo.data.trim().toLowerCase(),
			filter: reqFilters,
			start: moment().subtract(1, 'years').set({ hours: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
			end: moment().set({ hours: 0, minute: 0, second: 0, millisecond: 0 }).valueOf()
		});
	};

	const onFinish = async (values) => {
		setIsDownLoading(true);
		const start = moment(values.dateTimeRange[0]._d)
			.set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
			.valueOf();
		const end = moment(values.dateTimeRange[1]._d)
			.set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
			.valueOf();
		const filesToDownload = files.filter((element) => element.date >= start && element.date <= end);
		DownloadAll(filesToDownload);
	};

	const [ getIntersectionInfo, { onError, err } ] = useMutation(({ filter }) =>
		axios.get(`/api/buckets/${props.bucket}/file/${filter}ICTraffic`)
	);

	const [ getZipfiles, { isError, error } ] = useMutation(
		({ intersectionType, filter, start, end }) =>
			axios.get(
				`/api/buckets/${props.bucket}/${intersectionType}Objects?filters=${filter}&from=${start}&to=${end}`
			),
		{
			onSuccess: (result) => {
				setFiles(result.data);
				setTableLoading(false);
			}
		}
	);

	const DownloadAll = async (files) => {
		var zip = new jszip();
		const filePromises = [];
		files.forEach((element) => {
			element.content.forEach((file) => {
				filePromises.push(getfile(file.Key));
			});
		});

		Promise.all(filePromises)
			.then((fileContents) => {
				for (let i = 0; i < fileContents.length; i++) {
					zip.file(
						fileContents[i].headers['content-disposition'].split('filename="')[1].slice(0, -1),
						fileContents[i].data
					);
				}
				zip.file('kname', selectedIntersection);
				zip.generateAsync({ type: 'blob' }).then(function(content) {
					// see FileSaver.js
					saveAs(content, selectedIntersection);
					setIsDownLoading(false);
				});
			})
			.catch((err) => {
				setIsDownLoading(false);
			});
	};

	const getfile = (fileName) => {
		return axios.get(`/api/buckets/${props.bucket}/zip/${fileName.replace('/', '%2F')}`, {
			responseType: 'blob',
			timeout: 30000
		});
	};

	if (isLoading) return <Spin tip="Loading ..." />;
	if (isError) return <Alert message="Error" description={error.response.data.message} type="error" />;
	if (onError) return <Alert message="Error" description={err.response.data.message} type="error" />;
	return (
		<div>
			<Row>
				<Col span="24">
					<Title>
						<Text type="secondary">ICTraffic DataCollector</Text>
					</Title>
				</Col>
			</Row>
			<Row>
				<Form form={form} layout="inline" onFinish={onFinish}>
					<Form.Item
						label="Intersection"
						name="intersection"
						rules={[
							{
								required: true,
								message: 'Please select an intersection'
							}
						]}
					>
						<Select
							showSearch
							filterOption={(input, option) =>
								option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							style={{ width: '300px' }}
							onChange={onChange}
						>
							{intersections.map((intersection) => (
								<Option value={intersection} key={intersection}>
									{intersection}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Date-time range"
						name="dateTimeRange"
						rules={[
							{
								required: true,
								message: 'Please select a Date-time range'
							}
						]}
					>
						<RangePicker
							style={{ minHeight: '45px' }}
							format="YYYY-MM-DD"
							disabled={tableLoading}
							disabledDate={(date) => {
								return date > Date.now();
							}}
							dateRender={(current) => {
								const style = {};
								if (files.length > 0) {
									const match = files.find((file) => {
										return (
											file.date ===
											current.set({ hours: 0, minute: 0, second: 0, millisecond: 0 }).valueOf()
										);
									});
									if (match) {
										style.border = '1px solid #00B3B3';
										style.borderRadius = '50%';
									}
								}
								return (
									<div className="ant-picker-cell-inner" style={style}>
										{current.date()}
									</div>
								);
							}}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							icon={<DownloadOutlined />}
							loading={tableLoading || isDownLoading}
						>
							Download
						</Button>
					</Form.Item>
				</Form>
			</Row>
			{
				<Row>
					<Col span={24} align="left">
						<DownloadTable files={files} loading={tableLoading} />
					</Col>
				</Row>
			}
		</div>
	);
}

export { S3Dashboard };
