import React, { useState } from "react";
import { useMutation } from "react-query";
import { Table, Form, Button, Popconfirm, Spin, Alert } from "antd";
import axios from "axios";
import { EditableCell } from "./";

function EditableModulesTable({ modules }) {
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState("");

	const [
		updateModule,
		{ isLoading, isError, isSuccess, error },
	] = useMutation(
		({ name, updatedModule }) =>
			axios.put(`/api/modules/${name}`, updatedModule),
		{
			onSuccess: () => window.location.reload(true),
		}
	);

	const [
		deleteModule,
		{
			isLoading: isLoadingDelete,
			isError: isErrorDelete,
			isSuccess: isSuccessDelete,
			error: errorDelete,
		},
	] = useMutation(
		({ moduleId }) => axios.delete(`/api/modules/${moduleId}`),
		{
			onSuccess: () => window.location.reload(true),
		}
	);

	const isEditing = record => {
		return record.name === editingKey;
	};

	const edit = record => {
		form.setFieldsValue({
			name: "",
			...record,
		});
		setEditingKey(record.name);
	};

	const cancel = () => {
		setEditingKey("");
	};

	const save = async key => {
		const row = await form.validateFields();
		const module = modules.find(module => module.name === key);
		setEditingKey("");
		updateModule({ name: module.name, updatedModule: row });
	};

	const onDelete = (e, record) => {
		deleteModule({ moduleId: record.id });
	};

	let columns = [
		{
			title: "Name",
			dataIndex: "name",
			editable: true,
		},
		{
			title: "Action",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Button
							style={{ marginRight: 5 }}
							onClick={() => save(record.name)}
						>
							Save
						</Button>
						<Popconfirm
							title="Are you sure to cancel ?"
							onConfirm={cancel}
							okText="Yes"
							cancelText="No"
						>
							<Button>Cancel</Button>
						</Popconfirm>
					</span>
				) : (
					<div>
						<Button
							disabled={editingKey !== ""}
							onClick={() => edit(record)}
							style={{ marginRight: 5 }}
						>
							Edit
						</Button>
						<Popconfirm
							title="Are you sure you want to delete the module ?"
							cancelText="Cancel"
							onConfirm={e => onDelete(e, record)}
						>
							<Button>Delete</Button>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	columns = columns.map(col => {
		if (!col.editable) return col;

		return {
			...col,
			onCell: record => ({
				record,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	if (isLoading || isLoadingDelete) return <Spin tip="Loading..." />;
	if (isError || isErrorDelete)
		return (
			<Alert
				message="Error"
				description={
					error
						? error.response.data.message
						: errorDelete.response.data.message
				}
				type="error"
			/>
		);

	return (
		<div>
			{isSuccess && (
				<Alert
					message="Module successfully updated !"
					type="success"
					showIcon
				/>
			)}
			{isSuccessDelete && (
				<Alert
					message="Module successfully deleted !"
					type="success"
					showIcon
				/>
			)}
			<Form form={form} component={false}>
				<Table
					title={() => "Modules"}
					bordered
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					rowKey="name"
					columns={columns}
					dataSource={modules}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</div>
	);
}

export { EditableModulesTable };
