import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Table, Form, Button, Popconfirm, Spin, Alert } from "antd";
import { getEditorModules } from "./../_helpers";
import axios from "axios";
import { EditableCell } from "./";

function EditableDashboardsTable({ modules }) {
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState("");

	const editorModules = getEditorModules();
	const { isLoading, isError, data: dashboards, error } = useQuery(
		"dashboards",
		() =>
			axios.get(
				`/api/dashboards?${editorModules
					.map(module => `module=${module}`)
					.join("&")}`
			)
	);
	const [
		updateDashboard,
		{
			isLoading: isLoadingUpdate,
			isError: isErrorUpdate,
			isSuccess: isSuccessUpdate,
			error: errorUpdate,
		},
	] = useMutation(
		({ name, updatedDashboard }) =>
			axios.put(`/api/dashboards/${name}`, updatedDashboard),
		{
			onSuccess: () => window.location.reload(true),
		}
	);

	const [
		deleteDashboard,
		{
			isLoading: isLoadingDelete,
			isError: isErrorDelete,
			isSuccess: isSuccessDelete,
			error: errorDelete,
		},
	] = useMutation(
		({ dashboardId }) => axios.delete(`/api/dashboards/${dashboardId}`),
		{
			onSuccess: () => window.location.reload(true),
		}
	);

	const isEditing = record => {
		return record.name === editingKey;
	};

	const edit = record => {
		form.setFieldsValue({
			module: "",
			name: "",
			url: "",
			...record,
		});
		setEditingKey(record.name);
	};

	const cancel = () => {
		setEditingKey("");
	};

	const save = async key => {
		let row = await form.validateFields();
		row.module = row.moduleId;
		const dashboard = dashboards.data.find(
			dashboard => dashboard.name === key
		);
		setEditingKey("");
		updateDashboard({ name: dashboard.name, updatedDashboard: row });
	};

	const onDelete = (e, record) => {
		deleteDashboard({ dashboardId: record.id });
	};

	let columns = [
		{
			title: "Module",
			dataIndex: "moduleId",
			editable: true,
			inputType: "select",
			options: modules.map(module => ({
				value: module.id,
				label: module.name,
			})),
			sorter: (a, b) => a.module.localeCompare(b.module),
			render: (_, record) => record.module,
		},
		{
			title: "Dashboard",
			dataIndex: "name",
			editable: true,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "URL",
			dataIndex: "url",
			editable: true,
		},
		{
			title: "Action",
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Button
							style={{ marginRight: 5 }}
							onClick={() => save(record.name)}
						>
							Save
						</Button>
						<Popconfirm
							title="Are you sure to cancel ?"
							onConfirm={cancel}
							okText="Yes"
							cancelText="No"
						>
							<Button>Cancel</Button>
						</Popconfirm>
					</span>
				) : (
					<div>
						<Button
							disabled={editingKey !== ""}
							onClick={() => edit(record)}
							style={{ marginRight: 5 }}
						>
							Edit
						</Button>
						<Popconfirm
							title="Are you sure you want to delete the dashboard ?"
							cancelText="Cancel"
							onConfirm={e => onDelete(e, record)}
						>
							<Button>Delete</Button>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	columns = columns.map(col => {
		if (!col.editable) return col;

		return {
			...col,
			onCell: record => ({
				record,
				dataIndex: col.dataIndex,
				title: col.title,
				inputType: col.inputType,
				options: col.options,
				editing: isEditing(record),
			}),
		};
	});

	if (isLoading || isLoadingUpdate || isLoadingDelete)
		return <Spin tip="Loading..." />;
	if (isError || isErrorUpdate || isErrorDelete)
		return (
			<Alert
				message="Error"
				description={
					error
						? error.response.data.message
						: errorUpdate
						? errorUpdate.response.data.message
						: errorDelete.response.data.message
				}
				type="error"
			/>
		);

	return (
		<div>
			{isSuccessUpdate && (
				<Alert
					message="Dashboard successfully updated !"
					type="success"
					showIcon
				/>
			)}
			{isSuccessDelete && (
				<Alert
					message="Dashboard successfully deleted !"
					type="success"
					showIcon
				/>
			)}
			<Form form={form} component={false}>
				<Table
					title={() => "Dashboards"}
					bordered
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					rowKey="name"
					columns={columns}
					dataSource={dashboards.data}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</div>
	);
}

export { EditableDashboardsTable };
