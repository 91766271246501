import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, MapConsumer } from 'react-leaflet';
import { busIcon, fireIcon } from './';

function Mapcontainer(props) {
	let position = [ 51.21989, 4.40346 ];

	const messages = props.messages;
	return (
		<div className="map__container" style={{ width: '100%', height: '500px' }}>
			<MapContainer style={{ width: '100%', height: '500px' }} center={position} zoom={13} scrollWheelZoom={true}>
				<MapConsumer>
					{(map) => {
						if (messages.length > 0) {
							position = [ messages[0].Latitude, messages[0].Longitude ];
							map.setView([ messages[0].Latitude, messages[0].Longitude ], 15);
						}
						return null;
					}}
				</MapConsumer>
				<TileLayer
					attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{messages.length > 0 &&
					messages.map((marker) => {
						return <PopupMarker markerData={marker} key={marker.Timestamp} />;
					})}
			</MapContainer>
		</div>
	);
}

function PopupMarker(props) {
	const markerIcon = (marker) => {
		return marker.VehicleRole === 'publicTransport' ? busIcon : fireIcon;
	};

	return (
		<Marker
			icon={markerIcon(props.markerData)}
			position={[ props.markerData.Latitude, props.markerData.Longitude ]}
			key={props.markerData.Timestamp}
		>
			<Popup>
				<strong>SRM </strong> type {props.markerData.MessageTypeString} - {props.markerData.details}
				<br />
				<strong>Timestamp :</strong> {new Date(props.markerData.Timestamp).toLocaleString('en-GB')}
				<br />
				<strong>Vehicle type :</strong> {props.markerData.VehicleRole}
				<br />
				<strong>Vehicle Id :</strong> {props.markerData.Vehicle}
			</Popup>
		</Marker>
	);
}

export { Mapcontainer };
