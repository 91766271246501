import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Menu, Layout, Spin, Alert } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartBar, faUsers, faCogs } from '@fortawesome/free-solid-svg-icons';
import { getModules, isAdmin, isEditor } from './../_helpers';

const { SubMenu } = Menu;

function Sider(props) {
	const selectedKeys = props.location.pathname === '/' ? [ 'home' ] : props.location.pathname;
	const modulesOfUser = getModules();

	const { isLoading, isError, data: dashboards, error } = useQuery('dashboards', () =>
		axios.get(`/api/dashboards?${modulesOfUser.map((module) => `module=${module}`).join('&')}`)
	);

	if (isLoading) return <Spin tip="Loading..." />;
	if (isError) {
		return <Alert message="Error" description={error.response.data.message} type="error" />;
	}
	const groupedByModule = dashboards.data.reduce((acc, curr) => {
		acc[curr.module] = acc[curr.module] || [];
		acc[curr.module].push(curr);
		return acc;
	}, {});
	const modulesMenuItems = Object.keys(groupedByModule).map((moduleName) => (
		<SubMenu key={moduleName} title={moduleName}>
			{groupedByModule[moduleName].map((dashboard) => (
				<Menu.Item key={`/dashboard/${dashboard.name}`}>
					<Link
						to={{
							pathname: `/dashboard/${dashboard.name}`,
							state: { url: dashboard.url, dashboard: dashboard.name, module: moduleName }
						}}
					/>
					<FontAwesomeIcon icon={faChartBar} /> {dashboard.name}
				</Menu.Item>
			))}
		</SubMenu>
	));

	return (
		<Layout.Sider collapsible width={256}>
			<Menu mode="inline" selectedKeys={selectedKeys}>
				<Menu.Item key="home">
					<Link to="/">
						<FontAwesomeIcon icon={faHome} /> Home
					</Link>
				</Menu.Item>
				{isAdmin() && (
					<Menu.Item key="user-management">
						<Link to="/user-management">
							<FontAwesomeIcon icon={faUsers} /> User management
						</Link>
					</Menu.Item>
				)}
				{isEditor() && (
					<Menu.Item key="/configuration">
						<Link to="/configuration">
							<FontAwesomeIcon icon={faCogs} /> Configuration
						</Link>
					</Menu.Item>
				)}
				{modulesMenuItems}
			</Menu>
		</Layout.Sider>
	);
}

export { Sider };
