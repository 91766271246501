import React from "react";
import { useMutation } from "react-query";
import { Form, Input, Button, Spin, Alert } from "antd";
import axios from "axios";

function NewModuleForm() {
	const [form] = Form.useForm();

	const [
		createModule,
		{ isLoading, isError, isSuccess, error },
	] = useMutation(({ module }) => axios.post("/api/modules", module), {
		onSuccess: () => window.location.reload(true),
	});

	const onFinish = values => {
		createModule({ module: values });
	};

	if (isLoading) return <Spin tip="Loading..." />;
	if (isError)
		return (
			<Alert
				message="Error"
				description={error.response.data.message}
				type="error"
			/>
		);
	return (
		<div>
			{isSuccess && (
				<Alert
					message="Module successfully created !"
					type="success"
					showIcon
				/>
			)}
			<Form form={form} layout="inline" onFinish={onFinish}>
				<Form.Item
					label="Name"
					name="name"
					rules={[
						{
							required: true,
							message: "Please input a name",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-form-button"
					>
						Add new module
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
export { NewModuleForm };
