import React from "react";
import ReactDOM from "react-dom";
import { Routes } from "./Routes";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import "./style/siemens-design-system.less";
// eslint-disable-next-line
import tableau from "tableau-api";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

window.onbeforeunload = function() {
	localStorage.clear();
};

const queryCache = new QueryCache({
	defaultConfig: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

ReactDOM.render(
	<ReactQueryCacheProvider queryCache={queryCache}>
		<Routes />
	</ReactQueryCacheProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
