import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Spin, Form, Select, Button, DatePicker, Table, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { useMutation } from 'react-query';
import { Mapcontainer } from './';

const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function HistoricalDashboard(props) {
	const [ intersections, setIntersections ] = useState([]);
	const [ trips, setTrips ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isFormLoading, setIsFormLoading ] = useState(false);
	const [ form ] = Form.useForm();
	const [ messages, setMessages ] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/mobilidata/intersections`)
			.then((response) => {
				const intersections = response.data.map((element) => {
					return { name: getBEintersectionID(element[0].value), value: element[0].value };
				});
				setIntersections(intersections);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
		return () => {};
	}, []);

	const getBEintersectionID = (NLintersectionID) => {
		let id = NLintersectionID.substring(4);
		id = pad(parseInt(id, 16) / 10, 3);
		return 'K' + id;
	};

	const pad = (n, width, z) => {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	};

	const onTripChange = async (e, values) => {
		setIsFormLoading(true);
		const messages = await getTripMessages({ filter: values });
		const srm = messages.data.sort(function(x, y) {
			return new Date(x.Timestamp).getTime() - new Date(y.Timestamp).getTime();
		});
		setMessages(srm);
		setIsFormLoading(false);
	};

	const onFinish = async (values) => {
		setIsFormLoading(true);
		const tripIds = await getIntersectionTrips({ filter: values });
		setTrips(tripIds.data);
		setIsFormLoading(false);
	};

	const [ getIntersectionTrips ] = useMutation(({ filter }) =>
		axios.get(
			`/api/mobilidata/intersections/${filter.intersection}/trips?start=${moment(
				filter.dateTimeRange[0]
			).valueOf()}&end=${moment(filter.dateTimeRange[1]).valueOf()}`
		)
	);

	const [ getTripMessages ] = useMutation(({ filter }) =>
		axios.get(`/api/mobilidata/trips/${filter.value}/messages`)
	);

	const columns = [
		{
			title: 'TripId',
			dataIndex: 'TripID',
			key: 'TripID'
		},
		{
			title: 'Timestamp',
			dataIndex: 'Timestamp',
			key: 'Timestamp',
			render: (text) => new Date(text).toLocaleString(),
			sorter: (x, y) => new Date(x.Timestamp).getTime() - new Date(y.Timestamp).getTime()
		},
		{
			title: 'Vehicle',
			dataIndex: 'Vehicle',
			key: 'Vehicle'
		},
		{
			title: 'MessageTypeString',
			dataIndex: 'MessageTypeString',
			key: 'MessageTypeString'
		},
		{
			title: 'RequestID',
			dataIndex: 'RequestID',
			key: 'RequestID'
		},
		{
			title: 'RequestSequenceNumber',
			dataIndex: 'RequestSequenceNumber',
			key: 'RequestSequenceNumber'
		},
		{
			title: 'InboundOn',
			dataIndex: 'InboundOn',
			key: 'InboundOn'
		},
		{
			title: 'VehicleRole',
			dataIndex: 'VehicleRole',
			key: 'VehicleRole'
		},
		{
			title: 'details',
			dataIndex: 'details',
			key: 'details'
		}
	];

	if (isLoading) return <Spin tip="Loading ..." />;
	return (
		<div style={{ width: '100%', height: '100%' }}>
			<Row>
				<Col span="24">
					<Title>
						<Text type="secondary">Historical dashboard</Text>
					</Title>
				</Col>
			</Row>
			<Row>
				<Form form={form} layout="inline" onFinish={onFinish} style={{ width: '100%' }}>
					<Form.Item
						label="Intersection"
						name="intersection"
						rules={[
							{
								required: true,
								message: 'Please select an intersection'
							}
						]}
					>
						<Select
							showSearch
							disabled={isFormLoading}
							filterOption={(input, option) =>
								option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							style={{ width: '300px', maxWidth: '500px' }}
						>
							{intersections.map((intersection) => (
								<Option value={intersection.value} key={intersection.value}>
									{intersection.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Date-time range"
						name="dateTimeRange"
						rules={[
							{
								required: true,
								message: 'Please select a Date-time range'
							}
						]}
					>
						<RangePicker
							style={{ minHeight: '45px' }}
							showTime={{ format: 'HH:mm' }}
							format="YYYY-MM-DD HH:mm"
							disabledDate={(date) => {
								return date > Date.now();
							}}
							disabled={isFormLoading}
						/>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={isFormLoading} icon={<SearchOutlined />}>
							Search
						</Button>
					</Form.Item>
				</Form>
			</Row>
			<Row>
				<Form
					//form={form}
					layout="inline"
					style={{ width: '100%' }}
				>
					<Form.Item label="Trip" name="trip">
						<Select
							showSearch
							disabled={isFormLoading || trips.length === 0}
							filterOption={(input, option) =>
								option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							style={{ width: '500px', maxWidth: '500px' }}
							onChange={onTripChange}
						>
							{trips.map((trip) => (
								<Option value={trip[0].value} key={trip[0].value}>
									<strong>{trip[0].value}</strong> - {new Date(trip[1].value).toLocaleString()} -{' '}
									{new Date(trip[2].value).toLocaleTimeString()} - {trip[3].value} - SRM :{trip[4].value}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Row>
			<Tabs defaultActiveKey="1">
				<TabPane tab="Map" key="1">
					<Mapcontainer
						messages={messages.filter((msg) => msg.Latitude != null && msg.Longitude != null)}
						style={{ width: '100%', height: '500px' }}
					/>
				</TabPane>
				<TabPane tab="Table" key="2">
					<Table
						rowKey={(record) => record.Timestamp}
						dataSource={messages}
						columns={columns}
						pagination={{
							showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
							defaultPageSize: 20,
							defaultCurrent: 1
						}}
					/>
				</TabPane>
			</Tabs>
		</div>
	);
}

export { HistoricalDashboard };
