import React, { useEffect, useRef, useState } from "react";

function EmbeddedTableau(props) {
	const [viz, setViz] = useState(null);
	let container = useRef(null);

	useEffect(() => {
		if (viz) viz.dispose();

		const options = {
			hideTabs: true,
			width: "100%",
			height: "100%",
		};

		const url = `${props.url}?:alerts=no&:customViews=no&:display_count=no&:embed=true&:record_performance=no&:subscriptions:no&:tabs=no&:showAppBanner=false&:showShareOptions=false&:showVizHome=false&:toolbar=no`;
		setViz(new window.tableau.Viz(container.current, url, options));
		// eslint-disable-next-line
	}, [props.url]);

	return <div ref={container} style={{ width: "100%", height: "80vh" }} />;
}

export { EmbeddedTableau };
