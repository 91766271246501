import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { Header, Sider } from './../_components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { getUser } from './../_helpers';

const { Content, Footer } = Layout;

const isLoggedIn = () => {
	const user = getUser();
	return user !== null;
};

function RouteWrapper({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!isLoggedIn()) {
					if (props.location.pathname === '/dashboard/DataCollector')
						return <Redirect to="/DataCollector/login" />;
					else return <Redirect to="/login" />;
				}
				return (
					<Layout>
						<Header />
						<Layout>
							<Sider {...props} />
							<Layout>
								<Content>
									<Component {...props} user={getUser()} />
								</Content>
								<Footer style={{ textAlign: 'center' }}>
									Made with <FontAwesomeIcon icon={faHeart} color="#ff7675" /> by Yunex
								</Footer>
							</Layout>
						</Layout>
					</Layout>
				);
			}}
		/>
	);
}

export { RouteWrapper };
