import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { getDataCollectorUser, getUser } from './../_helpers';

const { Content, Footer } = Layout;

const isDataCollectorUserLoggedIn = () => {
	const user = getDataCollectorUser();
	return user !== null;
};

const isSSOUserLoggedIn = () => {
	const user = getUser();
	return user !== null;
};

const user = isSSOUserLoggedIn() ? getUser() : getDataCollectorUser();

function DataCollectorRouteWrapper({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!isDataCollectorUserLoggedIn() && !isSSOUserLoggedIn()) {
					return <Redirect to="/DataCollector/login" />;
				}
				return (
					<Layout style={{ height: '100%' }}>
						<Content>
							<Component {...props} user={user} />
						</Content>
						<Footer style={{ textAlign: 'center' }}>
							Made with <FontAwesomeIcon icon={faHeart} color="#ff7675" /> by SIEMENS Mobility
						</Footer>
					</Layout>
				);
			}}
		/>
	);
}

export { DataCollectorRouteWrapper };
