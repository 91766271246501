import React, { useState, useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { busIcon, fireIcon } from './';

function PopupMarker(props) {
	const [ visible, setVisible ] = useState(true);

	useEffect(
		() => {
			setVisible(true);
			const timer = setTimeout(() => {
				setVisible(false);
			}, 30000);
			return () => {
				clearTimeout(timer);
			};
		},
		[ props.markerData.payload.timestamp ]
	);

	const markerIcon = (marker) => {
		return marker.payload.cam.camParameters.lowFrequencyContainer.basicVehicleContainerLowFrequency.vehicleRole ===
		'publicTransport'
			? busIcon
			: fireIcon;
	};

	if (visible) {
		return (
			<Marker
				icon={markerIcon(props.markerData)}
				position={[
					props.markerData.payload.cam.camParameters.basicContainer.referencePosition.latitude / 10000000,
					props.markerData.payload.cam.camParameters.basicContainer.referencePosition.longitude / 10000000
				]}
				key={props.markerData.payload.header.stationID}
			>
				<Popup>
					<strong>Timestamp :</strong> {new Date(props.markerData.payload.timestamp).toLocaleString('en-GB')}
					<br />
					<strong>Vehicle type :</strong>{' '}
					{
						props.markerData.payload.cam.camParameters.lowFrequencyContainer
							.basicVehicleContainerLowFrequency.vehicleRole
					}
					<br />
					<strong>Vehicle Id :</strong> {props.markerData.payload.header.stationID}
					{props.markerData.srm &&
						props.markerData.srm.map((srmMsg) => (
							<span key={srmMsg.intersectionId}>
								<br /> <strong>SRM :</strong> {srmMsg.intersectionId} - {srmMsg.TTS}
							</span>
						))}
				</Popup>
			</Marker>
		);
	} else {
		return null;
	}
}

export { PopupMarker };
