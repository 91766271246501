import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { message, Spin } from "antd";

function LoginPage(props) {
	const [user, setUser] = useState(null);

	useEffect(() => {
		axios
			.get("/api/login/authenticated")
			.then(res => {
				const user = res.data;
				if (user !== null && user.strategy !== "local") {
					return axios
						.get(`/api/users/${user.username}/roles`)
						.then(response => response.data)
						.then(response => {
							user.modules = response.map(module => ({
								name: module.module,
								role: module.role,
							}));
							setUser(user);
							return localStorage.setItem(
								"deleien-user",
								JSON.stringify(user)
							);
						});
				} else {
					window.location.href = "/api/login";
				}
			})
			.catch(error => message.error(error.toString()));
	}, []);

	if (user) return <Redirect to="/" />;
	return (
		<div style={{ padding: "30px", textAlign: "center" }}>
			<Spin tip="Loading..." />
		</div>
	);
}

export { LoginPage };
