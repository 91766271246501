import React from "react";
import { useQuery, useQueryCache } from "react-query";
import { Row, Col, Typography, Spin, Alert } from "antd";
import { UsersTable } from "./UsersTable";
import { NewUserContainer } from "./NewUserContainer";
import { getAdministratedModules } from "./../_helpers";

import axios from "axios";

const { Title, Text } = Typography;

function UserManagementPage(props) {
	const cache = useQueryCache();
	const administratedModules = getAdministratedModules();

	// Queries
	const { isLoading, isError, data: users, error } = useQuery("users", () =>
		axios.get(
			`/api/users?${administratedModules
				.map(am => `module=${am}`)
				.join("&")}`
		)
	);

	const invalidateQueries = () => cache.invalidateQueries("users");

	if (isLoading) return <Spin tip="Loading ..." />;
	if (isError)
		return (
			<Alert
				message="Error"
				description={error.response.data.message}
				type="error"
			/>
		);

	return (
		<div>
			<Row>
				<Col span={24}>
					<Title>
						<Text type="secondary">User management</Text>
					</Title>
				</Col>
			</Row>
			<Row>
				<Col span={24} align="left">
					<NewUserContainer onCreated={invalidateQueries} />
				</Col>
			</Row>
			<Row>
				<Col span={24} align="center">
					<UsersTable
						users={users.data}
						onCompletedUpdate={invalidateQueries}
					/>
				</Col>
			</Row>
		</div>
	);
}

export { UserManagementPage };
