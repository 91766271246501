import React from 'react';
import { Table} from 'antd';
import moment from 'moment';

function DownloadTable({ files,  loading }) {
	// Mutations


	const columns = [
		{
			title: 'Date',
			dataIndex: 'date',
			render: (text, row, index) => {
				return moment(row.date).format('YYYY-MM-DD');
			},
			sorter: (a, b) => moment(a.date).format('YYYY-MM-DD').localeCompare(moment(b.date).format('YYYY-MM-DD'))
		},
		{
			title: 'Number of files',
			dataIndex: 'length',
			sorter: (a, b) => a.length.localeCompare(b.length)
		},
		{
			title: 'Type',
			dataIndex: 'installatieType',
			sorter: (a, b) => a.installatieType.localeCompare(b.installatieType)
		}
	];
	return (
		<div>
			<Table loading={loading} columns={columns} dataSource={files} rowKey="date" />
		</div>
	);
}

export { DownloadTable };
