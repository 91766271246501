import React from "react";
import { Spin, Alert, Modal, Form, Input, Select } from "antd";
import { useQuery } from "react-query";
import axios from "axios";
import { getAdministratedModules } from "./../_helpers";
import { roles as rolesConstants } from "./../_constants";

const { Option } = Select;

function UserModalForm({ visible, isUpdate, onComplete, onCancel, user }) {
	const [form] = Form.useForm();
	const administratedModules = getAdministratedModules();

	let userForm = null;
	if (user)
		userForm = user.modules.reduce((acc, curr) => {
			acc[`module.${curr.name}`] = curr.role;
			return acc;
		}, user);

	userForm = administratedModules.reduce((acc, curr) => {
		acc[`module.${curr}`] =
			acc[`module.${curr}`] || rolesConstants.unauthorized;
		return acc;
	}, userForm || {});

	const {
		isLoading: companiesQueryIsLoading,
		isError: companiesQueryIsError,
		data: companies,
		error: companiesQueryError,
	} = useQuery("companies", () => axios.get("/api/companies"));

	const {
		isLoading: rolesQueryIsLoading,
		isError: rolesQueryIsError,
		data: roles,
		error: rolesQueryError,
	} = useQuery("roles", () => axios.get("/api/roles"));

	if (companiesQueryIsLoading || rolesQueryIsLoading)
		return <Spin tip="Loading ..." />;
	if (companiesQueryIsError || rolesQueryIsError)
		return (
			<Alert
				message="Error"
				description={
					companiesQueryError.response.data.message ||
					rolesQueryError.response.data.message
				}
				type="error"
			/>
		);

	const modulesItems = administratedModules.map((module, index) => (
		<Form.Item
			key={index}
			name={`module.${module}`}
			label={`Role for module ${module}`}
			rules={[{ required: true, message: "Please select a role" }]}
		>
			<Select>
				{roles.data.map((role, index) => (
					<Option key={index} value={role.name}>
						{role.name}
					</Option>
				))}
			</Select>
		</Form.Item>
	));

	const onOk = () => {
		form.validateFields().then(values => {
			form.resetFields();
			onComplete(values);
		});
	};

	return (
		<Modal
			visible={visible}
			title={isUpdate ? "Update user" : "Create new user"}
			okText={isUpdate ? "Update" : "Create"}
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={onOk}
		>
			<Form
				form={form}
				initialValues={userForm}
				layout="vertical"
				name="form_in_modal"
			>
				<Form.Item
					name="username"
					label="Username"
					rules={[
						{ required: true, message: "Please input a username" },
					]}
				>
					<Input disabled={isUpdate} />
				</Form.Item>
				<Form.Item
					name="firstname"
					label="First name"
					rules={[
						{
							required: true,
							message: "Please input a first name",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="lastname"
					label="Last name"
					rules={[
						{ required: true, message: "Please input a last name" },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					rules={[
						{ required: true, message: "Please input an email" },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="company"
					label="Company"
					rules={[
						{ required: true, message: "Please select a company" },
					]}
				>
					<Select placeholder="Select a company">
						{companies.data.map((company, index) => (
							<Option key={index} value={company.name}>
								{company.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				{modulesItems}
			</Form>
		</Modal>
	);
}

export { UserModalForm };
