import React, { useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { Spin, Alert, Table, Popconfirm, Button } from "antd";
import { UserModalForm } from "./UserModalForm";
import Swal from "sweetalert2";

function UsersTable({ users, onCompletedUpdate }) {
	const [selectedUser, setSelectedUser] = useState(null);
	const [visibleModalForm, setVisibleModalForm] = useState(false);

	// Mutations
	const [
		updateUser,
		{
			isLoading: isLoadingUpdate,
			isError: isErrorUpdate,
			error: errorUpdate,
		},
	] = useMutation(user => axios.post(`/api/users/${user.username}`, user), {
		onSuccess: () => {
			hideModal();
			Swal.fire({
				icon: "success",
				title: "User updated successfully !",
				showConfirmButton: false,
				timer: 3000,
			});
			onCompletedUpdate();
		},
	});

	const [
		updatePassword,
		{
			isLoading: isLoadingPassword,
			isError: isErrorPassword,
			error: errorPassword,
		},
	] = useMutation(
		({ username, newPassword }) =>
			axios.post(`/api/users/${username}/password`, {
				password: newPassword,
			}),
		{
			onSuccess: (data, variables) => {
				Swal.fire({
					icon: "success",
					title: "Password updated successfully !",
					showConfirmButton: true,
					html: `Please send her/his new password.<br />Password: <i>${variables.newPassword}</i>`,
				});
			},
		}
	);

	const [
		deleteUser,
		{
			isLoading: isLoadingDelete,
			isError: isErrorDelete,
			error: errorDelete,
		},
	] = useMutation(({ username }) => axios.delete(`/api/users/${username}`), {
		onSuccess: () => {
			Swal.fire({
				icon: "success",
				title: "User deleted successfully !",
				showConfirmButton: false,
				timer: 3000,
			});
			onCompletedUpdate();
		},
	});

	if (isLoadingUpdate || isLoadingPassword || isLoadingDelete)
		return <Spin tip="Loading ..." />;
	if (isErrorUpdate || isErrorPassword || isErrorDelete)
		return (
			<Alert
				message="Error"
				description={
					isErrorUpdate
						? errorUpdate.response.data.message
						: isErrorPassword
						? errorPassword.response.data.message
						: errorDelete.response.data.message
				}
				type="error"
			/>
		);

	users = users.reduce((acc, cur) => {
		acc[cur.username] = acc[cur.username] || [];
		acc[cur.username].push(cur);
		return acc;
	}, {});

	const usersRows = Object.values(users).map(user => ({
		...user[0],
		modules: user.map(userArray => ({
			name: userArray.module,
			role: userArray.role,
		})),
	}));

	const onPasswordReset = (e, record) => {
		const newPassword = Math.random()
			.toString(36)
			.slice(-10);
		updatePassword({ username: record.username, newPassword: newPassword });
	};

	const onEdit = (e, record) => {
		setSelectedUser(record);
		setVisibleModalForm(true);
	};

	const onDelete = (e, record) => {
		deleteUser({ username: record.username });
	};

	const hideModal = () => setVisibleModalForm(false);

	const onUpdate = async values => {
		const modules = Object.keys(values)
			.filter(property => property.startsWith("module."))
			.map(property => ({
				name: property.replace("module.", ""),
				role: values[property],
			}));
		for (const key in values) {
			if (key.startsWith("module.")) delete values[key];
		}

		const user = { ...values, modules };
		updateUser(user);
	};

	const columns = [
		{
			title: "Username",
			dataIndex: "username",
			sorter: (a, b) => a.username.localeCompare(b.username),
			onFilter: (value, record) => record.username.indexOf(value) === 0,
		},
		{
			title: "First name",
			dataIndex: "firstname",
			sorter: (a, b) => a.firstname.localeCompare(b.firstname),
		},
		{
			title: "Last name",
			dataIndex: "lastname",
			sorter: (a, b) => a.lastname.localeCompare(b.lastname),
		},
		{
			title: "Company",
			dataIndex: "company",
			sorter: (a, b) => a.company.localeCompare(b.company),
		},
		{
			title: "Email",
			dataIndex: "email",
			sorter: (a, b) => a.email.localeCompare(b.email),
		},
		{
			title: "Actions",
			render: (text, record) => (
				<span>
					<Button
						style={{ marginRight: 5 }}
						onClick={e => onEdit(e, record)}
					>
						Edit
					</Button>
					<Popconfirm
						title="Are you sure you want to reset the password ?"
						cancelText="Cancel"
						onConfirm={e => onPasswordReset(e, record)}
					>
						<Button style={{ marginRight: 5 }}>
							Reset password
						</Button>
					</Popconfirm>
					<Popconfirm
						title="Are you sure you want to delete the user ?"
						cancelText="Cancel"
						onConfirm={e => onDelete(e, record)}
					>
						<Button>Delete</Button>
					</Popconfirm>
				</span>
			),
		},
	];

	const expandedRowRender = record => {
		const expandedColumns = [
			{
				title: "Module",
				dataIndex: "name",
			},
			{
				title: "Role",
				dataIndex: "role",
			},
		];

		return (
			<Table
				rowKey="name"
				columns={expandedColumns}
				dataSource={record.modules}
				bordered={true}
				size="small"
				pagination={false}
			/>
		);
	};

	return (
		<div>
			<UserModalForm
				isUpdate={true}
				visible={visibleModalForm}
				onCancel={hideModal}
				onComplete={onUpdate}
				user={selectedUser}
			/>
			<Table
				rowKey="username"
				columns={columns}
				dataSource={usersRows}
				expandable={{ expandedRowRender }}
			/>
		</div>
	);
}

export { UsersTable };
