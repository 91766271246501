import React from "react";
import { Form, Input, Select } from "antd";

const { Option } = Select;

const EditableCell = ({
	editing,
	dataIndex,
	title,
	record,
	children,
	index,
	inputType,
	options,
	...restProps
}) => {
	let inputNode = <Input />;
	if (inputType === "select")
		inputNode = (
			<Select
				showSearch
				style={{ width: "100%" }}
				placeholder={`Please select ${title}`}
			>
				{options.map(option => (
					<Option value={option.value} key={option.value}>
						{option.label}
					</Option>
				))}
			</Select>
		);
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					rules={[
						{ required: true, message: `Please input ${title}` },
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

export { EditableCell };
