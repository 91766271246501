import { roles } from "./../_constants";

const userItem = "deleien-user";
const dataCollectorUserItem = "dataCollector-user";

export function getUser() {
	return localStorage.getItem(userItem)
		? JSON.parse(localStorage.getItem(userItem))
		: null;
}

export function getDataCollectorUser() {
	return localStorage.getItem(dataCollectorUserItem)
		? JSON.parse(localStorage.getItem(dataCollectorUserItem))
		: null;
}
export function getAdministratedModules() {
	let localUser = localStorage.getItem(userItem);
	if (!localUser) return [];
	localUser = JSON.parse(localUser);
	return localUser.modules
		.filter(module => module.role === roles.administrator)
		.map(module => module.name);
}

export function getEditorModules() {
	let localUser = localStorage.getItem(userItem);
	if (!localUser) return [];
	localUser = JSON.parse(localUser);
	return localUser.modules
		.filter(
			module =>
				module.role === roles.editor ||
				module.role === roles.administrator
		)
		.map(module => module.name);
}

export function getModules() {
	let localUser = localStorage.getItem(userItem);
	if (!localUser) return [];
	localUser = JSON.parse(localUser);
	return localUser.modules
		.filter(module => module.role !== roles.unauthorized)
		.map(module => module.name);
}

export function isAdmin() {
	let localUser = localStorage.getItem(userItem);
	if (!localUser) return false;
	localUser = JSON.parse(localUser);
	return localUser.modules.some(
		module => module.role === roles.administrator
	);
}

export function isEditor() {
	let localUser = localStorage.getItem(userItem);
	if (!localUser) return false;
	localUser = JSON.parse(localUser);
	return localUser.modules.some(
		module =>
			module.role === roles.editor || module.role === roles.administrator
	);
}
