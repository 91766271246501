import React, { useEffect, useState } from "react";
import {
    Select,
    Typography,
    Row,
    Col,
    Tabs,
    Form,
    Input,
    Button,
    Radio,
    InputNumber,
    Spin,
    message,
    Checkbox,
} from "antd";
import axios from "axios";
import { getAdministratedModules, getEditorModules } from "../_helpers/users";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

function AgConfiguration(props) {
    const [config, setConfig] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEditor, setIsEditor] = useState(false);

    useEffect(() => {
        setIsAdmin(getAdministratedModules().includes("ANPR Geetbets"))
        setIsEditor(getEditorModules().includes("ANPR Geetbets"))
        axios
            .get(
                "/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fconfig_file.json"
            )
            .then(response => {
                setConfig(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                message.error(error);
            });
    }, []);

    const onFinish = values => {
        setIsLoading(true);
        setConfig(values);
        axios
            .post(
                "/api/buckets/dig.its.anpr.sluip/file/Geetbets_dorp_steenstraat%2fconfig_whitelist%2fconfig_file.json",
                values
            )
            .then(response => {
                message.success("Configuration updated");
                setIsLoading(false);
            })
            .catch(error => {
                message.error("Error while updating");
                setIsLoading(false);
            });
    };

    if (isLoading) return <Spin tip="Loading ..." />;
    return (
        <div>
            <Row>
                <Col span="24">
                    <Title level={1}>Configuration</Title>
                </Col>
            </Row>
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                initialValues={config}
                onFinish={onFinish}
            >
                <Tabs id="Tab" defaultActiveKey="1">
                    <TabPane
                        tab="Basis instellingen"
                        key="1"
                        forceRender={true}
                    >
                        <Form.Item
                            name="alpha_enable_route"
                            label="Route Noord-Zuid"
                            required={true}
                            help="Aanbevolen: Aan"
                        >
                            <Radio.Group>
                                <Radio value={true}>Aan</Radio>
                                <Radio value={false}>Uit</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="beta_enable_route"
                            label="Route Zuid-Noord"
                            required={true}
                            help="Aanbevolen: Aan"
                        >
                            <Radio.Group>
                                <Radio value={true}>Aan</Radio>
                                <Radio value={false}>Uit</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="beta_red_time"
                            label="Rood tijd NZ (in milliseconden):"
                            required={true}
                            help="Aanbevolen: 30000 ms"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="beta_time_out"
                            label="Minimale tijd tussen rood(in ms) NZ"
                            required={true}
                            help="Aanbevolen: 10000 ms"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="alpha_red_time"
                            label="Rood tijd ZN (in milliseconden):"
                            required={true}
                            help="Aanbevolen: 30000 ms"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="alpha_time_out"
                            label="Minimale tijd tussen rood(in ms) NZ"
                            required={true}
                            help="Aanbevolen: 10000 ms"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="msg_form_speeding"
                            label="Snelheidsovertreding bericht"
                            required={true}
                            help="Gebruik alleen lowercase en highercase karakters en deze tekens .!?+-/*%()"
                        >
                            <Input
                                pattern="[1-9A-Za-z./*%+-/(/)\s!?]+"
                                title="Should only contain lowercase and highercase characters, and these signs .!?+-/*%()"
                            />
                        </Form.Item>
                        <Form.Item
                            name="msg_form_through"
                            label="Doorrijdend verkeer bericht"
                            required={true}
                            help="Gebruik alleen kleine letters, hoofdletters, cijfers en deze tekens .!?+-/*%()"
                        >
                            <Input
                                pattern="[1-9A-Za-z./*%+-/(/)\s!?]+"
                                title="Gebruik alleen kleine letters, hoofdletters, cijfers en deze tekens .!?+-/*%()"
                            />
                        </Form.Item>
                        <Form.Item
                            name="color_vms_num"
                            label="Kleur nummerplaattekst"
                            required={true}
                            help="Aanbevolen: White"
                        >
                            <Select style={{ width: 300 }}>
                                <Option value="7">White</Option>
                                <Option value="6">Aqua</Option>
                                <Option value="5">Fuchsia</Option>
                                <Option value="4">Blue</Option>
                                <Option value="3">Yellow</Option>
                                <Option value="2">Green</Option>
                                <Option value="1">Red</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="color_text_num"
                            label="Kleur overtredingstekst"
                            required={true}
                            help="Aanbevolen: White"
                        >
                            <Select style={{ width: 300 }}>
                                <Option value="7">White</Option>
                                <Option value="6">Aqua</Option>
                                <Option value="5">Fuchsia</Option>
                                <Option value="4">Blue</Option>
                                <Option value="3">Yellow</Option>
                                <Option value="2">Green</Option>
                                <Option value="1">Red</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="time_camera_on"
                            label="Uren camera actief"
                            required={true}
                            help="Aanbevolen: tussen 6u - 21u"
                        >
                            <Checkbox.Group style={{ width: "100%" }}>
                                <Row>
                                    <Col>
                                        <Checkbox value="0">0u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="1">1u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="2">2u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="3">3u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="4">4u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="5">5u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="6">6u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="7">7u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="8">8u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="9">9u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="10">10u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="11">11u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="12">12u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="13">13u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="14">14u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="15">15u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="16">16u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="17">17u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="18">18u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="19">19u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="20">20u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="21">21u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="22">22u</Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox value="23">23u</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        { (isEditor || isAdmin) && 
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                        }  
                    </TabPane>
                    
                    <TabPane
                        tab="Advanced Settings - North"
                        key="2"
                        forceRender={true}
                        disabled={!isAdmin}
                    >
                        
                        <Form.Item
                            name="alpha_debug_mode"
                            label="Debug mode"
                            required={true}
                            help="Recommended: Disable"
                        >
                            <Radio.Group>
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            name="alpha_time_route"
                            label="Time of route (exit ZN)"
                            required={true}
                            help="Recommended: 14000"
                        >
                     
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item
                            name="alpha_time_margin"
                            label="Time of margin (exit ZN)"
                            required={true}
                            help="Recommended: 1000"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="alpha_time_speeding"
                            label="Time of speeding (exit ZN)"
                            required={true}
                            help="Recommended: 55000"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="alpha_server_camera"
                            label="IP for camera to post results to"
                            required={true}
                            help="Recommended: 192.168.10.10"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="alpha_camera_port"
                            label="Push/Pull camera port"
                            required={true}
                            help="Recommended: 8080"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="alpha_departing"
                            label="Departing direction camera"
                            required={true}
                            help="Recommended: Entry"
                        >
                            <Radio.Group>
                                <Radio value={"entry"}>Entry</Radio>
                                <Radio value={"exit"}>Exit</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="alpha_approaching"
                            label="Approaching direction camera"
                            required={true}
                            help="Recommended: Exit"
                        >
                            <Radio.Group>
                                <Radio value={"entry"}>Entry</Radio>
                                <Radio value={"exit"}>Exit</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="alpha_vrm_confidence"
                            label="VRM confidence"
                            required={true}
                            help="Recommended: 60%"
                        >
                            <InputNumber min={0} max={100} />
                        </Form.Item>
                        <Form.Item
                            name="alpha_VMS_enabled"
                            label="VMS"
                            required={true}
                            help="Recommended: Disable, if no VMS present"
                        >
                            <Radio.Group>
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="alpha_TLC_enabled"
                            label="Traffic light"
                            required={true}
                            help="Recommended: Disable, if no traffic light present"
                        >
                            <Radio.Group>
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="alpha_IP_address"
                            label="IP of RCM"
                            required={true}
                            help="Recommended: 192.168.255.102"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="alpha_tunnel_IP_address"
                            label="IP of connected RCM"
                            required={true}
                            help="Recommended: 192.168.255.100"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                        
                    </TabPane>
                    <TabPane
                        tab="Advanced Settings - South"
                        key="3"
                        forceRender={true}
                        disabled={!isAdmin}
                    >
                        <Form.Item
                            name="beta_debug_mode"
                            label="Debug mode"
                            required={true}
                            help="Recommended: Disable"
                        >
                            <Radio.Group>
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="beta_time_route"
                            label="Time of route (exit NZ)"
                            required={true}
                            help="Recommended: 140000"
                        >
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item
                            name="beta_time_margin"
                            label="Time of margin (exit NZ)"
                            required={true}
                            help="Recommended: 1000"
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            name="beta_time_speeding (exit NZ)"
                            label="Time of speeding"
                            required={true}
                            help="Recommended: 55000"
                        >
                            <InputNumber min={0}/>
                        </Form.Item>
                        <Form.Item
                            name="beta_server_camera"
                            label="IP for camera to post results to"
                            required={true}
                            help="Recommended: 192.168.131.105"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="beta_camera_port"
                            label="Push/Pull camera port"
                            required={true}
                            help="Recommended: 8080"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="beta_departing"
                            label="Departing direction camera"
                            required={true}
                            help="Recommended: Entry"
                        >
                            <Radio.Group>
                                <Radio value={"entry"}>Entry</Radio>
                                <Radio value={"exit"}>Exit</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="beta_approaching"
                            label="Approaching direction camera"
                            required={true}
                            help="Recommended: Exit"
                        >
                            <Radio.Group>
                                <Radio value={"entry"}>Entry</Radio>
                                <Radio value={"exit"}>Exit</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="beta_vrm_confidence"
                            label="VRM confidence"
                            required={true}
                            help="Recommended:60%"
                        >
                            <InputNumber min={0} max={100} />
                        </Form.Item>
                        <Form.Item
                            name="beta_VMS_enabled"
                            label="VMS"
                            required={true}
                            help="Recommended: Disable, if no VMS present"
                        >
                            <Radio.Group>
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="beta_TLC_enabled"
                            label="TLC"
                            required={true}
                            help="Recommended: Disable, if no Traffic light present"
                        >
                            <Radio.Group>
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="beta_IP_address"
                            label="IP of RCM"
                            required={true}
                            help="Recommended: 192.168.255.100"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="beta_tunnel_IP_address"
                            label="IP of connected RCM"
                            required={true}
                            help="Recommended: 192.168.255.102"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </TabPane>
                </Tabs>
            </Form>
        </div>
    );
}

export { AgConfiguration };
