import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { PopupMarker } from './';
import socketIOClient from 'socket.io-client';

function LiveMonitoringPage(props) {
	const [ messages, setMessages ] = useState([]);
	const [ position ] = useState([ 51.21989, 4.40346 ]);

	useEffect(() => {
		const socket = socketIOClient();
		socket.on('fastForward', (data) => {
			if (data.payload.cam) {
				let markers = messages;
				let isMarkerPresent = false;
				markers.forEach((marker, index, object) => {
					if (new Date().getTime() - new Date(marker.payload.timestamp).getTime() > 30000)
						object.splice(index, 1);
					else {
						if (marker.payload.header.stationID === data.payload.header.stationID) {
							marker.payload.cam = data.payload.cam;
							marker.payload.timestamp = data.payload.timestamp;
							isMarkerPresent = true;
						}
					}
				});
				if (!isMarkerPresent) markers.push(data);

				setMessages([ ...markers ]);
			}
			if (data.payload.srm && data.payload.srm.requests[0].request.id.region === 32001) {
				let markers = messages;
				const srm = data.payload.srm;
				markers.forEach((message) => {
					if (message.payload.header.stationID === data.payload.header.stationID) {
						const srmData = {
							intersectionId: getBEintersectionID(data.topic),
							TTS:
								(parseInt(srm.requests[0].minute) * 60000 +
									parseInt(srm.requests[0].second) -
									(parseInt(srm.timeStamp) * 60000 + parseInt(srm.second))) /
								1000
						};
						if (message.srm) {
							let msg = message.srm.filter((msg) => {
								return msg.intersectionId !== srmData.intersectionId;
							});
							msg.push(srmData);
							message.srm = msg;
						} else {
							message.srm = [ srmData ];
						}
					}
				});
				setMessages([ ...markers ]);
			}
		});
		return () => {
			socket.disconnect();
		};
	}, []);

	const getBEintersectionID = (topic) => {
		let id = topic.split('/')[0].substring(4);
		id = pad(parseInt(id, 16) / 10, 3);
		return 'K' + id;
	};
	const pad = (n, width, z) => {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	};

	return (
		<div className="map__container" style={{ width: '100%', height: '100%' }}>
			<MapContainer style={{ width: '100%', height: '100%' }} center={position} zoom={13} scrollWheelZoom={true}>
				<TileLayer
					attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{messages.length > 0 &&
					messages.map((marker) => {
						return <PopupMarker markerData={marker} key={marker.payload.header.stationID} />;
					})}
			</MapContainer>
		</div>
	);
}

export { LiveMonitoringPage };
