import React from "react";
import { useQuery } from "react-query";
import { Row, Col, Typography, Spin, Alert } from "antd";
import { getEditorModules } from "./../_helpers";
import axios from "axios";
import {
	EditableModulesTable,
	EditableDashboardsTable,
	NewModuleForm,
	NewDashboardForm,
} from "./";

const { Title, Text } = Typography;

function ConfigurationPage() {
	const editorModules = getEditorModules();
	const { isLoading, isError, data: modules, error } = useQuery(
		"modules",
		() =>
			axios.get(
				`/api/modules?${editorModules
					.map(module => `module=${module}`)
					.join("&")}`
			)
	);

	if (isLoading) return <Spin tip="Loading..." />;
	if (isError)
		return (
			<Alert
				message="Error"
				description={error.response.data.message}
				type="error"
			/>
		);
	return (
		<div>
			<Row>
				<Col span={24}>
					<Title>
						<Text type="secondary">Configuration</Text>
					</Title>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<NewModuleForm />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<EditableModulesTable modules={modules.data} />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<NewDashboardForm modules={modules.data} />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<EditableDashboardsTable modules={modules.data} />
				</Col>
			</Row>
		</div>
	);
}

export { ConfigurationPage };
