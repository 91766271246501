import React from "react";
import { Row, Col } from "antd";
import { EmbeddedTableau, EmbeddedKibana, S3Dashboard } from "./../_components";
import { LiveMonitoringPage, HistoricalDashboard } from "./../Fast Forward";
import { AgConfiguration, AgWhitelist } from "./../ANPR_Geetbets";

function DashboardPage(props) {
	const url = props.location.state.url.trim();
	const module = props.location.state.module;
	const dashboard = props.location.state.dashboard;

	let Component = null;

	if (url !== "") {
		if (url.includes("tableau.com"))
			Component = <EmbeddedTableau url={url} />;
		else if (url.includes("kibana") || url.includes("datadoghq"))
			Component = <EmbeddedKibana url={url} />;
		else Component = <S3Dashboard bucket={url} />;
	} else {
		if (
			module.toLowerCase() === "fast forward" &&
			dashboard.toLowerCase() === "live monitoring"
		)
			Component = <LiveMonitoringPage />;
		else if (
			module.toLowerCase() === "fast forward" &&
			dashboard.toLowerCase() === "historical dashboard"
		)
			Component = <HistoricalDashboard />;
		else if (
			module.toLowerCase() === "anpr geetbets" &&
			dashboard.toLowerCase() === "configuration anpr"
		)
			Component = <AgConfiguration />;
		else if (
			module.toLowerCase() === "anpr geetbets" &&
			dashboard.toLowerCase() === "whitelist anpr"
		)
			Component = <AgWhitelist />;
	}

	return (
		<Row style={{ height: "100%" }}>
			<Col span={24}>{Component}</Col>
		</Row>
	);
}

export { DashboardPage };
