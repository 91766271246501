import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Form, Button, message, Typography, Input } from 'antd';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const boxStyle = {
	boxShadow: '0 0 50px rgba(0,0,0,.1)',
	padding: '20px'
};

function DataCollectorLogin(props) {
	const [ user, setUser ] = useState(null);
	const [ form ] = Form.useForm();

	useEffect(() => {
		axios
			.get('/api/login/authenticated')
			.then((res) => {
				const user = res.data;
				if (user !== null) {
					return axios
						.get(`/api/users/${user.username}/roles`)
						.then((response) => response.data)
						.then((response) => {
							user.modules = response.map((module) => ({
								name: module.module,
								role: module.role
							}));
							const ictrafficmodule = user.modules.find((elem) => elem.name === 'ICTraffic');
							if (ictrafficmodule && ictrafficmodule.role !== 'unauthorized') {
								setUser(user);
								return localStorage.setItem('dataCollector-user', JSON.stringify(user));
							} else {
								message.error('unauthorized');
							}
						});
				}
			})
			.catch((error) => message.error(error.toString()));
	}, []);

	const onFinish = (values) => {
		axios
			.post('/api/login', {
				username: values.username,
				password: values.password
			})
			.then((res) => {
				const user = res.data;
				if (user !== null) {
					return axios
						.get(`/api/users/${user.username}/roles`)
						.then((response) => response.data)
						.then((response) => {
							user.modules = response.map((module) => ({
								name: module.module,
								role: module.role
							}));
							const ictrafficmodule = user.modules.find((elem) => elem.name === 'ICTraffic');
							if (ictrafficmodule && ictrafficmodule.role !== 'unauthorized') {
								setUser(user);
								return localStorage.setItem('dataCollector-user', JSON.stringify(user));
							} else {
								message.error('unauthorized');
							}
						});
				}
			})
			.catch((error) => {
				console.log(error);
				if (error.message.includes('400')) message.error('Missing username or password');
				else if (error.message.includes('401')) message.error('Wrong username or password');
				else message.error('Something went wrong');
			});
	};

	if (user)
		return (
			<Redirect
				to={{
					pathname: `/dashboard/DataCollector`,
					state: { url: 'dig.its.ictrafficonline.inbound.field' }
				}}
			/>
		);
	return (
		<Row align="middle" style={{ height: '100%' }}>
			<Col span={8} offset={8} align="center" style={boxStyle}>
				<Title>
					<Text type="secondary">ICTraffic One</Text>
				</Title>
				<Form form={form} onFinish={onFinish} className="login-form">
					<Form.Item name="username">
						<Input autoComplete="off" prefix={<UserOutlined />} placeholder="Username" />
					</Form.Item>
					<Form.Item name="password">
						<Input.Password prefix={<LockOutlined />} type="password" placeholder="Password" />
					</Form.Item>
					<Form.Item>
						<Button
							block
							type="primary"
							htmlType="submit"
							className="login-form-button"
							icon={<LoginOutlined />}
						>
							Log in
						</Button>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
}

export { DataCollectorLogin };
