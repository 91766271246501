import React from "react";
import { Layout, Typography, Menu } from "antd";
import { SettingFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getUser } from "./../_helpers";

const { Text } = Typography;

const siemensLogo = require("./../_assets/yunex_traffic_logo.png");
const deLijnLogo = require("./../_assets/de_lijn_logo.png");
const stadAntwerpenLogo = require("./../_assets/stad_anwterpen_logo.jpg");
const awvLogo = require("./../_assets/awv_logo.svg");

const style = {
	logo: {
		maxHeight: "75%",
		padding: "5px",
	},
};

function Header(props) {
	let logo = null;
	const user = getUser();
	switch (user.company) {
		case "AWV":
			logo = <img src={awvLogo} style={style.logo} alt="AWV logo" />;
			break;
		case "De Lijn":
			logo = (
				<img src={deLijnLogo} style={style.logo} alt="De Lijn logo" />
			);
			break;
		case "Stad Anwterpen":
			logo = (
				<img
					src={stadAntwerpenLogo}
					style={style.logo}
					alt="Stad Antwerpen logo"
				/>
			);
			break;
		default:
			break;
	}
	return (
		<Layout.Header style={{ position: "fixed", zIndex: 10, width: "100%" }}>
			<Layout.Header>
				<div className="left" style={{ height: "100%" }}>
					<Link to="/" style={{ float: "left", height: "100%" }}>
						<img
							src={siemensLogo}
							style={style.logo}
							alt="Siemens logo"
						/>
						{logo}
					</Link>
					<Text type="primary" style={{ fontSize: "16px" }}>
						ICTraffic One
					</Text>
				</div>
				<div className="right">
					<Menu mode="horizontal">
						<Menu.SubMenu
							title={
								<span className="submenu-title-wrapper">
									<SettingFilled />
								</span>
							}
						>
							<Menu.Item key="/myprofile">
								<Link to="/myprofile">My profile</Link>
							</Menu.Item>
						</Menu.SubMenu>
					</Menu>
				</div>
			</Layout.Header>
		</Layout.Header>
	);
}

export { Header };
